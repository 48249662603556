import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link as RouterLink } from 'react-router-dom'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Helmet } from 'react-helmet'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import { useStripe } from '@stripe/react-stripe-js'

import {
	chakra,
	Badge,
	Link,
	Box,
	Alert,
	Text,
	Container,
	Spinner,
	Heading,
	Button,
	keyframes,
	VStack,
	Flex,
	Stack,
	List,
	ListItem,
	HStack,
	Image,
} from '@chakra-ui/react'
import Card from '../../../atoms/Card'
import useEntities from '../../../../hooks/useEntities'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { getRecipients } from '@vidday/utils'
import { formatToDecimalAmount } from '../../../../../api/checkout/utils'
import useInvoices from '../../../../hooks/useInvoices'
import { useDispatch } from 'react-redux'
import { fetchEvents } from '../../../../../api/app/events'
import { CheckCircleRounded, InfoRounded } from '../../../atoms/Icon'
import configClient from '../../../../../../client/config.client'
import useBeginCheckoutEvent from '../../../../hooks/useBeginCheckoutEvent'
import getGAClientId from '../../../../../utils/getGAClientId'

dayjs.extend(localizedFormat)

const tableStyles = {
	w: 'full',
	mx: 'auto',
}

const trStyles = {
	'whiteSpace': 'nowrap',
	'width': 'full',

	'_hover': {
		backgroundColor: '#F4F4F5',
	},
	'&:last-child td': {
		borderBottom: 'none !important',
	},
	'& td:first-of-type': {
		pl: '0.5rem',
	},
}

const tdStyles = {
	py: ['0.5rem', '0.5rem', '1rem'],
	pr: '1rem',
	borderBottom: '1px solid #E7ECF0',
}

const thStyles = {
	py: ['0.5rem', '0.5rem', '1rem'],
	fontSize: '0.875rem',
	fontWeight: 'bold',
	verticalAlign: 'middle',
	color: '#47596A',
	textAlign: 'left',
	borderBottom: '1px solid #E7ECF0',
}

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: configClient.api.baseUrl,
})

const NoTransaction = ({ isFetching }) => {
	return (
		<chakra.tr>
			<chakra.td colSpan="6" align="center">
				{isFetching ? (
					<Spinner m="3rem" />
				) : (
					<Text p="2rem">
						<chakra.strong>There are no Invoices to display.</chakra.strong>
					</Text>
				)}
			</chakra.td>
		</chakra.tr>
	)
}

const TableRow = ({ data }) => {
	const {
		id,
		uuid,
		currencyCode,
		chargeId,
		receiptUrl,
		status,
		message,
		description,
		recipients,
		amount,
		createdAt,
	} = data

	let hasCurrency = currencyCode ? true : false
	var chargeIdVal = chargeId ? (chargeId ? chargeId : id) : 'n/a'
	var receiptUrlId = receiptUrl ? (
		<chakra.a
			_hover={{ textDecoration: 'underline' }}
			color="var(--vidday-colors-link)"
			href={receiptUrl}
			title={chargeId}
			target="_blank"
			rel="noreferrer">
			View Receipt
		</chakra.a>
	) : (
		chargeIdVal
	)
	var recieptUrlMsg = receiptUrl ? (
		<chakra.a href={receiptUrl} target="_blank" rel="noreferrer">
			<Badge colorScheme={['succeeded', 'paid'].includes(status) ? 'green' : 'red'}>{message}</Badge>
		</chakra.a>
	) : (
		<chakra.span>
			{' '}
			<Badge colorScheme={['succeeded', 'paid'].includes(status) ? 'green' : 'red'}>{message}</Badge>
		</chakra.span>
	)

	var recipientNames = getRecipients(recipients, false).names

	return (
		<chakra.tr sx={trStyles}>
			<chakra.td sx={tdStyles}>
				{uuid ? (
					<Link
						as={RouterLink}
						color="var(--vidday-colors-link)"
						fontWeight="bold"
						title={description}
						to={`/event/${uuid}`}>
						{(description || recipientNames).length > 30
							? `${(description || recipientNames).slice(0, 30)}...`
							: description || recipientNames}
					</Link>
				) : (
					<Text
						variant="title"
						color="var(--vidday-colors-link)"
						noOfLines="1"
						maxW="480px"
						title={description}>
						{description?.length > 30 ? `${description.slice(0, 30)}...` : description}
					</Text>
				)}
			</chakra.td>
			<chakra.td sx={tdStyles}>{receiptUrlId}</chakra.td>
			<chakra.td sx={tdStyles} display={['none', 'table-cell']}>
				{dayjs(createdAt).format('ll')}
			</chakra.td>
			{hasCurrency && <chakra.td sx={tdStyles}>{formatToDecimalAmount(amount, `${currencyCode}$`)}</chakra.td>}
			{!hasCurrency && <chakra.td sx={tdStyles}>USD${(amount / 100).toFixed(2)}</chakra.td>}
			<chakra.td sx={tdStyles} display={['none', 'table-cell']}>
				{recieptUrlMsg}
			</chakra.td>
		</chakra.tr>
	)
}

const formatInvoices = ({ invoices, events, recipients, isImpersonated }) => {
	var invoiceByDate = orderBy(invoices, ['createdAt'], ['desc'])
	var invoiceOnly = isImpersonated
		? invoiceByDate
		: filter(invoiceByDate, function (invoice) {
				return invoice && (invoice.chargeId != null || invoice.gratuit)
		  })

	var recipientsByEventId = groupBy(recipients, 'eventId')
	var invoiceByEventId = groupBy(invoiceOnly, 'eventId')

	for (let key in invoiceByEventId) {
		if (recipientsByEventId[key]) {
			filter(invoiceOnly, function (invoice) {
				return invoice.eventId == key ? (invoice.recipients = recipientsByEventId[key]) : invoice
			})
		}
	}
	// attach uuid
	for (let key in invoiceByEventId) {
		if (events[key]) {
			filter(invoiceOnly, function (invoice) {
				return invoice.eventId == key ? (invoice.uuid = events[key].uuid) : invoice
			})
		}
	}

	let transactions = Object.values(invoiceOnly).map(function (el, i) {
		return <TableRow key={i} data={el} />
	})

	return transactions
}

const Billing = (props) => {
	/** Fetch All invoice for this Authed User */
	useInvoices(true)
	const { email, hasSubscription, isImpersonated } = useAuthContext()

	/** get stripe sdk for checkout redirect! */
	const stripe = useStripe()

	/** Fetch ALL events without pagination */
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchEvents())
	}, [])

	const [isFetchingInvoices, invoices] = useEntities('invoices')
	const [isFetchingEvents, events] = useEntities('events')
	const [isFetchingRecipients, recipients] = useEntities('recipients')

	const [transactionsView, setTransactionsView] = useState(<NoTransaction isFetching={isFetchingInvoices} />)

	useEffect(() => {
		if (isFetchingInvoices == false && isFetchingEvents == false) {
			let view = formatInvoices({ invoices, events, recipients, isImpersonated })
			if (view?.length > 0) {
				setTransactionsView(view)
			} else {
				setTransactionsView(<NoTransaction isFetching={isFetchingInvoices} />)
			}
		}
	}, [events, invoices])

	const handleCreateCheckoutSession = async () => {
		// Set redirect urls (only when window is defined)
		if (typeof window !== 'undefined' && window) {
			const cancelUrl = `${window.location.href}?cancel=true`
			const successUrl = `${window.location.href}?success=true`
			const analyticsClientId = getGAClientId()

			// do the post request
			/** POST Contribution and retrieve Stripe Checkout session ID */
			try {
				const response = await instance.post(`/v2/subscriptions/checkout`, {
					successUrl,
					cancelUrl,
					analyticsClientId: analyticsClientId || undefined, // don't include when its null
				})
				const { checkoutSessionId } = response.data.data
				useBeginCheckoutEvent(checkoutSessionId)
				const result = await stripe.redirectToCheckout({ sessionId: checkoutSessionId })
				if (result.error) {
					throw new Error('Stripe error : ' + result.error)
				}
			} catch (err) {
				console.error('Subscription checkout failed! ', err)
			}
		}
	}

	/**
	 *  Fancy background
	 */
	const gradient = keyframes`
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
	`
	const animatedBackground = {
		background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
		// background: 'linear-gradient(120deg, #0066CC 62%, #06CB, #0066CC 63%) right/250% 100%', // Glare flash
		backgroundSize: '400% 100%',
		animation: `${gradient} 30s ease infinite`,
		willChange: 'background-position',
	}

	return (
		<>
			<Helmet>
				<title>VidDay - Billing</title>
			</Helmet>

			<Container maxW="container.lg" py="2rem" variant="main">
				{!hasSubscription && (
					<Card
						px={['1rem', '2rem']}
						py="1rem"
						variant="main"
						mb="2rem"
						mx={['1rem', 0]}
						borderRadius={['xl']}
						sx={animatedBackground}>
						<Stack
							w="full"
							spacing="1rem"
							justifyContent="start"
							direction={['column', 'row']}
							alignItems="center">
							<Box maxW="300px">
								<Image
									src="/assets/images/graphics/billing/vidday-one-free-month.png"
									alt="Claim one month free"
								/>
							</Box>
							<Box>
								<VStack align="left" ml={['0', '2rem']}>
									<Heading color="white" size="xl" mt="2rem">
										Claim your free month!
									</Heading>
									<List color="white">
										<ListItem ml=".125rem">
											<CheckCircleRounded /> Free VidDays
										</ListItem>
										<ListItem ml=".125rem">
											<CheckCircleRounded /> Priority Processing
										</ListItem>
										<ListItem ml=".125rem">
											<CheckCircleRounded /> Unlimited Republishes
										</ListItem>
										<ListItem ml=".125rem">
											<CheckCircleRounded /> eCards for All Occasions
										</ListItem>
									</List>
									<Stack direction={['column-reverse', 'row']} alignItems={['left', 'center']}>
										{isFetchingInvoices || isFetchingEvents ? (
											<Spinner color="white" m="3rem" />
										) : Object.keys(invoices).length > 0 ? (
											<Button
												onClick={handleCreateCheckoutSession}
												target="_blank"
												rel="noreferrer"
												variant="invert"
												borderColor="white"
												w={['full', '170px']}
												my="1rem">
												Become a Member
											</Button>
										) : (
											<Text color="white">
												<InfoRounded ml="4px" me=".25rem" mt="-.125rem" width="1rem" />
												On your next checkout, become a member.
											</Text>
										)}
										<Flex direction={['column']} spacing="0" pl=".5rem">
											<Text color="white" fontWeight="700">
												30 days free
											</Text>
											<Text color="white">$9.99/month after • Cancel anytime</Text>
											{/*
											<Text color="white" fontSize="xs">
												Personal use only - Enterprise users &nbsp;
												<chakra.a
													_hover={{ textDecoration: 'underline' }}
													color="var(--vidday-colors-link)"
													href={'mailto:enterprise@vidday.com'}
													title={'enterprise'}
													target="_blank"
													rel="noreferrer">
													contact us
												</chakra.a>
											</Text>
											*/}
										</Flex>
									</Stack>
								</VStack>
							</Box>
						</Stack>
					</Card>
				)}
				<Card
					px={['1rem', '2rem']}
					py="1rem"
					alignItems="flex-start"
					variant="main"
					mb="2rem"
					mx={['1rem', 0]}
					borderRadius={['xl']}
					overflow="scroll">
					<chakra.table sx={tableStyles}>
						<chakra.tbody>
							<chakra.tr>
								<chakra.th sx={thStyles}>Invoice For</chakra.th>
								<chakra.th sx={thStyles}>Receipt Link</chakra.th>
								{/* <chakra.th sx={thStyles}>Order ID</chakra.th> */}
								<chakra.th sx={thStyles} display={['none', 'table-cell']}>
									Date
								</chakra.th>
								<chakra.th sx={thStyles}>Amount</chakra.th>
								<chakra.th sx={thStyles} display={['none', 'table-cell']}>
									Status
								</chakra.th>
							</chakra.tr>
							{transactionsView}
						</chakra.tbody>
					</chakra.table>
				</Card>

				{hasSubscription && (
					<Card
						px={['1rem', '2rem']}
						py="1rem"
						variant="main"
						mb="2rem"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<Flex w="full" justifyContent="space-between" alignItems="center">
							<VStack align="left">
								<Heading size="md">Membership</Heading>
								<Text>Free VidDays • Discounted Quality Checks • Free Republishes</Text>
							</VStack>
							<Button
								as="a"
								href={`${configClient.stripe.customerPortal}?prefilled_email=${encodeURIComponent(
									email
								)}`}
								target="_blank"
								rel="noreferrer"
								variant="outline">
								Manage Membership
							</Button>
						</Flex>
					</Card>
				)}

				<Box mx={['1rem', 0]}>
					<Alert status="warning" borderRadius="base" mb="1rem" backgroundColor="rgba(255,175,47, 0.1)">
						<Text fontSize="0.875rem" color="#47596A">
							⚠️ &nbsp; All amounts are in USD unless specified otherwise — publishing a VidDay results in
							an automatic invoice sent by email.
						</Text>
					</Alert>
				</Box>
			</Container>
		</>
	)
}

export default Billing
